<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="处理结果">
                  <a-select placeholder="请选择处理结果" v-model="queryParam.processStatus" style="width: 100%" allow-clear>
                    <a-select-option value="1">
                      待处理
                    </a-select-option>
                    <a-select-option value="2">
                      已处理
                    </a-select-option>
                    <a-select-option value="3">
                      暂不处理
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="联系方式" prop="contact">
                  <a-input v-model="queryParam.contact" placeholder="请输入联系方式" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <!-- 操作 -->
      <!-- <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['app:feedback:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['app:feedback:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['app:feedback:remove']">
          <a-icon type="delete" />删除
        </a-button>
       
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div> -->
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
        :bordered="tableBordered">
        <!---图片显示--->
        <span slot="pic" slot-scope="text, record">
          <!-- <img style="width:50px;heigth:50px" :src="item" v-for="(item, key) in record.imgUrls ? JSON.parse(record.imgUrls) : []" :key="key" /> -->
          <viewer :images="record.imgList" v-if="record.imgList!=null&&record.imgList.length > 0">
            <div class="state-img" style="width: 100px; display: flex; flex-wrap: wrap;">
              <img v-for="(item, index) in record.imgList " :key="'upimgs' + index" :src="item" alt="" style="width: 30px; height: 30px;">
              <!-- <img :src="record.imgList[0]" alt=""> -->

            </div>
          </viewer>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <div v-if="record.processStatus == 1">
            <a @click="$refs.createForm.handleUpdate(record, undefined)">
              <a-icon type="edit" />处理
            </a>
          </div>
          <!-- <a-divider type="vertical" v-hasPermi="['app:feedback:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['app:feedback:remove']">
            <a-icon type="delete" />删除
          </a> -->
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { pageFeedback, listFeedback, delFeedback } from '@/api/door/feedback'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
export default {
  name: 'Feedback',
  components: {
    CreateForm,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [
      ],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        type: null,
        content: null,
        processStatus: null,
        processResult: null,
        imgUrls: null,
        contact: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '反馈类型',
          dataIndex: 'typeStr',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '反馈内容',
          dataIndex: 'content',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '处理状态',
          dataIndex: 'processStatusStr',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '处理结果',
          dataIndex: 'processResult',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '反馈图片',
          dataIndex: 'imgUrls',
          scopedSlots: { customRender: 'pic' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '反馈时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询意见反馈列表 */
    getList() {
      this.loading = true
      pageFeedback(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.list.forEach(element => {
          //1-意见建议、2-故障报告、3-投诉
          if (element.type == 0) {
            element.typeStr = "产品建议"
          } else if (element.type == 1) {
            element.typeStr = "功能故障"
          } else if (element.type == 2) {
            element.typeStr = "其他"
          }
          //:1-待处理，2-已处理，3-暂不处理
          if (element.processStatus == 1) {
            element.processStatusStr = "待处理"
          } else if (element.processStatus == 2) {
            element.processStatusStr = "已处理"
          } else if (element.processStatus == 3) {
            element.processStatusStr = "暂不处理"
          }
          if(element.imgUrls){
            element.imgList=element.imgUrls.split(',')
          }
        });
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        userId: undefined,
        type: undefined,
        content: undefined,
        processStatus: undefined,
        processResult: undefined,
        imgUrls: undefined,
        contact: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          let data = []
          that.ids.map(id => data.push({ "id": id }))
          return delFeedback(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('app/feedback/export', {
            ...that.queryParam
          }, `意见反馈_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>
